import * as Yup from 'yup'
import { Formik } from 'formik'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { toast } from 'react-toastify'
import dynamic from 'next/dynamic'
import NiceModal from '@ebay/nice-modal-react'
import { captureException } from "@sentry/nextjs"

import { constants } from '@/lib/constants'
import { sendNewsletterDetailsService } from '@/lib/services/userService'
import { addToDataLayer } from '@/lib/utilities/vehicle'
import { postAnalyticsEvent } from '@/store/actions/event'
import styles from './newsletter-widget.module.scss'
import { ButtonHierarchy } from '@/components/styleguide/Button'
import { TextInput } from '@/components/styleguide/TextInput'
import { Size, Theme } from '@/types/system'
import { isProd } from '@/lib/utilities/system'

const Icon = dynamic(() => import('@/components/styleguide/Icon'))
const RecaptchaModal = dynamic(() => import('@/components/modals/RecaptchaModal'))

export type NewsletterWidgetProps = {
  isInline?: boolean
  hasLargeTitle?: boolean
  location: string
}

export const NewsletterWidget = ({
  isInline = false,
  hasLargeTitle = false,
  location,
}: NewsletterWidgetProps) => {
  const newsletterFormSchema = Yup.object({
    newsletterEmail: Yup.string()
      .email('Please enter a valid email address')
      .required('Your email address is required'),
  })
  const [subscribeStatus, setSubscribeStatus] = useState(constants.componentStatus.DEFAULT)
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })
  const dispatch = useDispatch()

  const onSubmitForm = (values: { newsletterEmail: string }) => {
    NiceModal.show(RecaptchaModal as FC).then(value => {
      sendNewsletterDetailsService({
        emailAddress: values.newsletterEmail,
        'g-recaptcha-response': value,
      }).then(res => {
        if (res.error) {
          setSubscribeStatus(constants.componentStatus.ERROR)
        } else {
          setSubscribeStatus(constants.componentStatus.COMPLETE)
          toast('Successfully signed up for newsletter')
          addToDataLayer({
            event: 'user-newsletter-signup',
            label: location,
          })
          dispatch(
            postAnalyticsEvent({
              event: constants.eventTypes.newsletterSignup,
              payload: {
                formType: 'widget',
                emailAddressOnly: false,
              },
            }),
          )
          if (typeof window.ttq !== 'undefined') {
            window.ttq.track('Subscribe', {
              // eslint-disable-next-line camelcase
              content_id: `Newsletter subscribe`,
              // eslint-disable-next-line camelcase
              content_type: 'product',
              // eslint-disable-next-line camelcase
              content_name: 'Newsletter subscribe',
              quantity: 1,
              value: null,
              price: null,
              currency: 'GBP',
            })
          }
        }
      }).catch((e) => {
          captureException('Failed to sign up to newsletter', e)
          setSubscribeStatus(constants.componentStatus.ERROR)
        })
    })
  }

  return (
    <div
      className={styles.main}
      data-is-inline={isInline}>
      {hasLargeTitle ? (
        <div className={styles.text}>
          <h4 className={`${styles.title} ${styles.large}`}>
            Sign up for exclusive deals, Loco updates, latest news and more.
          </h4>
        </div>
      ) : (
        <div className={styles.text}>
          <h4 className={styles.title}>Sign up for our newsletter</h4>
          <p className={styles.paragraph}>Get news, car tips & hottest offers</p>
        </div>
      )}
      <div className={styles['form-wrapper']}>
        <Formik
          validationSchema={newsletterFormSchema}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{ newsletterEmail: '' }}
          onSubmit={values => onSubmitForm(values)}>
          {({ handleSubmit }) => (
            <form
              className={styles.form}
              onSubmit={handleSubmit}>
              <TextInput
                id="newsletterEmail"
                placeholder={`Enter your email${isDesktop ? ' address' : ''}`}
                name="email"
                autoComplete="email"
                disabled={subscribeStatus === constants.componentStatus.COMPLETE}
                button={{
                  size: Size.Medium,
                  hierarchy: ButtonHierarchy.Filled,
                  theme: Theme.light,
                  label: 'Subscribe',
                  type: 'submit',
                  className: !isProd() ? 'newsletter__subscribe-button' : '',
                }}
              />
            </form>
          )}
        </Formik>
      </div>
      {(subscribeStatus === constants.componentStatus.COMPLETE ||
        subscribeStatus === constants.componentStatus.ERROR) && (
          <div
            data-state={subscribeStatus}
            className={styles.notification}>
            <Icon
              customClass={styles['notification--icon']}
              name={subscribeStatus === constants.componentStatus.COMPLETE ? 'CheckCircle2' : 'Info'}
              size={Size.Medium}
            />
            <p className={styles['notification--text']}>
              {subscribeStatus === constants.componentStatus.COMPLETE
                ? "Great, you'll get the first email soon"
                : 'We were unable to sign your email up. Please try again later.'}
            </p>
          </div>
        )}
    </div>
  )
}
