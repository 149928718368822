import { FieldHookConfig, useField } from 'formik'
import React, { useRef } from 'react'

import { Button, ButtonComponentProps, ButtonHierarchy } from '../Button'
import styles from './text-input.module.scss'
import { Theme } from '@/types/system'

export type TextInputProps = {
  id: string
  label?: string
  hint?: string
  placeholder: string
  button?: ButtonComponentProps
  disabled?: boolean
  visual?: boolean
  hideHintText?: boolean
  passwordrules?: string
  inputPrefixNode?: React.ReactNode
} & FieldHookConfig<'string'> &
  React.InputHTMLAttributes<HTMLInputElement>

export const TextInput = ({
  label,
  hint,
  id,
  placeholder,
  button,
  disabled,
  visual,
  type,
  required,
  inputMode,
  pattern,
  hideHintText = false,
  min,
  max,
  passwordrules,
  inputPrefixNode,
  className = '',
}: TextInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [field, meta] = useField({ name: id })

  return (
    <div
      className={`${styles.main} ${className}`}
      data-visual={visual}
      data-input-type={type}
      data-disabled={disabled || visual === true}>
      {label && (
        <label
          htmlFor={id}
          className={styles.label}>
          {label}
          {required && '*'}
        </label>
      )}
      {inputPrefixNode}
      <div
        className={`${styles.box} ${button && button.label ? styles['with-button'] : ''}`}
        data-error={!!meta.error}
        onClick={() => inputRef.current?.focus()}>
        {/* eslint-disable react/no-unknown-property */}
        <input
          id={id}
          className={styles.input}
          placeholder={placeholder}
          disabled={disabled || visual === true}
          ref={inputRef}
          type={type}
          inputMode={inputMode}
          pattern={pattern}
          {...field}
          min={min}
          max={max}
          passwordrules={passwordrules}
        />
        {/* eslint-enable react/no-unknown-property */}
        {button && (
          <Button
            {...button}
            hierarchy={ButtonHierarchy.Filled}
            disabled={disabled}
            theme={button.theme ? button.theme : Theme.dark}
          />
        )}
      </div>
      {!hideHintText && (hint || meta.error) && (
        <span
          className={styles.foot}
          data-error={!!meta.error}>
          {meta.error || hint}
        </span>
      )}
    </div>
  )
}
