export const getGuideCategoryName = urlKey => {
  switch (urlKey) {
    case 'personal-car-leasing':
      return 'Personal Car Leasing'
    case 'contracts-and-finance':
      return 'Contracts & Finance'
    case 'business-car-leasing':
      return 'Business Car Leasing'
    case 'hybrid-and-electric-leasing':
      return 'Hybrid & Electric Leasing'
    case 'van-leasing-guide':
      return 'Van Leasing'
    case 'leasing-with-leaseloco':
      return 'Leasing With LeaseLoco'
    default:
      return 'LeaseLoco Guide'
  }
}

export const getGuideCategoryType = urlKey => {
  switch (urlKey) {
    case 'personal-car-leasing':
      return 'GUIDE_PERSONAL'
    case 'contracts-and-finance':
      return 'GUIDE_CONTRACTS_FINANCE'
    case 'business-car-leasing':
      return 'GUIDE_BUSINESS'
    case 'hybrid-and-electric-leasing':
      return 'GUIDE_ELECTRIC'
    case 'van-leasing-guide':
      return 'GUIDE_VAN'
    case 'leasing-with-leaseloco':
      return 'GUIDE_LEASELOCO'
    default:
      return 'GUIDE_LEASING'
  }
}

export const getReviewHeading = text => {
  switch (text) {
    case 'tenSecondReview':
      return 'Ten second review'
    case 'background':
      return 'Background'
    case 'designAndBuild':
      return 'Design and build'
    case 'marketAndModel':
      return 'Market and model'
    case 'costOfOwnership':
      return 'Cost of ownership'
    case 'summary':
      return 'Summary'
    default:
      return text
  }
}

export const isVowel = letter => {
  return letter === 'A' || letter === 'E' || letter === 'I' || letter === 'O' || letter === 'U'
}

export const getTrustpilotStarRating = rating => {
  if (rating >= 1 && rating <= 1.2) {
    return 1
  }

  if (rating >= 1.3 && rating <= 1.7) {
    return 1.5
  }

  if (rating >= 1.8 && rating <= 2.2) {
    return 2
  }

  if (rating >= 2.3 && rating <= 2.7) {
    return 2.5
  }

  if (rating >= 2.8 && rating <= 3.2) {
    return 3
  }

  if (rating >= 3.3 && rating <= 3.7) {
    return 3.5
  }

  if (rating >= 3.8 && rating <= 4.2) {
    return 4
  }

  if (rating >= 4.3 && rating <= 4.7) {
    return 4.5
  }

  if (rating >= 4.8 && rating <= 5) {
    return 5
  }

  return rating
}

export const getTrustpilotLabel = rating => {
  if (rating >= 1 && rating <= 1.7) {
    return 'Bad'
  }

  if (rating >= 1.8 && rating <= 2.7) {
    return 'Poor'
  }

  if (rating >= 2.8 && rating <= 3.7) {
    return 'Average'
  }

  if (rating >= 3.8 && rating <= 4.2) {
    return 'Great'
  }

  if (rating >= 4.3 && rating <= 5) {
    return 'Excellent'
  }

  return rating
}
